import logo from '../assets/svgs/likewise-logo.svg';
import loginImage from '../assets/images/login/login.jpeg';

import { privacyText } from './likewise/privacy';
import { termsText } from './likewise/terms';
import { cookiesText } from './likewise/cookies';
import imgCarpet from './../assets/images/Carpet.png';
import imgVinyl from './../assets/images/Vinyl.png';
import imgLaminate from './../assets/images/Laminate.png';
import imgLVT from './../assets/images/LVT.png';
import imgCarpetTiles from './../assets/images/Carpet-Tiles.png';
import imgGrass from './../assets/images/Grass.png';
import imgMatting from './../assets/images/Matting.png';
import imgContractFloors from './../assets/images/Contract.png';
import imgScreed from './../assets/images/Screed.png';
import imgProducts from '../assets/images/Products.png';
import search from '../assets/svgs/searchLW.svg';
import cart from '../assets/svgs/cartLW.svg';

import GenericBanner from '../assets/images/Products.png';
import A_Accessories from '../assets/images/category-banners/A_Accessories.jpg';
import B_Adhesives_Screeds from '../assets/images/category-banners/B_Adhesives_Screeds.jpg';
import C_Carpet from '../assets/images/category-banners/C_Carpet.jpg';
import E_Contract from '../assets/images/category-banners/E_Contract.jpg';
import G_Grass from '../assets/images/category-banners/G_Grass.jpg';
import H_Underlay from '../assets/images/category-banners/H_Underlay.jpg';
import L_LVT from '../assets/images/category-banners/L_LVT.jpg';
import N_Laminate from '../assets/images/category-banners/N_Laminate.jpg';
import O_Mats_Runners from '../assets/images/category-banners/Cat_O_Banner_Mats_and_Runners_V2.jpg';
import Q_Matting from '../assets/images/category-banners/Q_Matting.jpg';
import R_Rugs from '../assets/images/category-banners/R_Rugs.jpg';
import T_CarpetTile from '../assets/images/category-banners/T_CarpetTile.jpg';
import V_Banner_Vinyl from '../assets/images/category-banners/V_Banner_Vinyl.jpg';
import A_Accessories_Image from '../assets/images/categories/Cat_A_Accessories_V2.jpeg';
import B_Adhesives_Screeds_Image from '../assets/images/categories/B_Adhesive_Screeds.jpg';
import C_Carpet_Image from '../assets/images/categories/C_Carpet.jpg';
import E_Contract_Image from '../assets/images/categories/E_Contract.jpg';
import G_Grass_Image from '../assets/images/categories/G_Grass.jpeg';
import H_Underlay_Image from '../assets/images/categories/H_Underlay.jpg';
import L_LVT_Image from '../assets/images/categories/L_LVT.jpg';
import N_Laminate_Image from '../assets/images/categories/N_Laminate.jpg';
import O_Mats_Runners_Image from '../assets/images/categories/Cat_O_Mats_and_Runners_V2.jpeg';
import Q_Matting_Image from '../assets/images/categories/Q_Matting.jpg';
import R_Rugs_Image from '../assets/images/categories/Cat_R_Rugs_V2.jpeg';
import T_CarpetTile_Image from '../assets/images/categories/T_Carpet_Tiles.jpg';
import V_Banner_Vinyl_Image from '../assets/images/categories/V_Vinyl.jpg';
import checked from '../assets/svgs/checkedLW.svg';
import orangeCheckmarkSvg from '../assets/svgs/orange-checkmark.svg';
import orangCircleSvg from '../assets/svgs/orange-circle.svg';
import Warning from '../assets/svgs/danger-sign.svg';
import back from '../assets/images/Back.png';
import Matting_accessories from '../assets/images/categories/Matting_Dashboard_accessories_image.jpeg';
import Matting_accessories_banner from '../assets/images/category-banners/Matting_Category_banner_accessories.jpeg';

// import homeBanner1 from '../assets/images/homebanners/harrogate_banner.jpg';
// import homeMob from '../assets/images/homebanners/mobile_banner1.jpg';

import homeBanner1 from '../assets/images/homebanners/Likewise-Banner-Vesta_Vinyl.jpg';
import homeMob from '../assets/images/homebanners/Likewise_Flooring_Vesta_Vinyl_Banner_Mobile.jpg';


import Matting_banner_1 from '../assets/images/category-banners/Matting_Dashboard_banner_1.jpg';
import Matting_banner_2 from '../assets/images/category-banners/Matting_Dashboard_banner_2.jpg';
import Matting_banner_3 from '../assets/images/category-banners/Matting_Dashboard_banner_3.jpg';

import Clearance_banner from '../assets/images/clearance/Clearance_Specials_Web_Banner_Desktop_V4.1.jpg';
import Clearance_mobile from '../assets/images/clearance/Clearance_Specials_Web_Banner_Category_Cover_V1.jpg';
import Clearance_tile from '../assets/images/clearance/Clearance_Specials_Web_Banner_Category_Cover_V2.jpg';
import Clearance_category_banner from '../assets/images/clearance/Clearance_Specials_Web_Banner_Desktop_V5.jpg';
import { priceListConfig } from './pricelists';
import  Pos_banner  from '../assets/images/homebanners/Desktop.jpg';
import  Pos_mobile  from '../assets/images/homebanners/Mobile.jpg';


declare let window: any;

export const config = {
  site: 'likewise',
  orderAtoZ: false,
  checked: checked,
  likewiseLogo: logo,
  showAbout: true,
  companyName: 'Likewise Floors',
  copyrightText: 'Likewise PLC. All Rights Reserved.',
  privacyText: privacyText,
  termsText: termsText,
  cookiesText: cookiesText,
  imgCarpet: imgCarpet,
  imgVinyl: imgVinyl,
  imgLaminate: imgLaminate,
  imgLVT: imgLVT,
  imgCarpetTiles: imgCarpetTiles,
  imgGrass: imgGrass,
  imgMatting: imgMatting,
  imgContractFloors: imgContractFloors,
  imgScreed: imgScreed,
  imgProducts: imgProducts,
  GenericBanner: GenericBanner,
  A_Accessories: A_Accessories,
  B_Adhesives_Screeds: B_Adhesives_Screeds,
  C_Carpet: C_Carpet,
  E_Contract: E_Contract,
  G_Grass: G_Grass,
  H_Underlay: H_Underlay,
  L_LVT: L_LVT,
  N_Laminate: N_Laminate,
  O_Mats_Runners: O_Mats_Runners,
  Q_Matting: Q_Matting,
  R_Rugs: R_Rugs,
  T_CarpetTile: T_CarpetTile,
  V_Banner_Vinyl: V_Banner_Vinyl,
  A_Accessories_Image: A_Accessories_Image,
  B_Adhesives_Screeds_Image: B_Adhesives_Screeds_Image,
  C_Carpet_Image: C_Carpet_Image,
  E_Contract_Image: E_Contract_Image,
  G_Grass_Image: G_Grass_Image,
  H_Underlay_Image: H_Underlay_Image,
  L_LVT_Image: L_LVT_Image,
  N_Laminate_Image: N_Laminate_Image,
  O_Mats_Runners_Image: O_Mats_Runners_Image,
  Q_Matting_Image: Q_Matting_Image,
  R_Rugs_Image: R_Rugs_Image,
  T_CarpetTile_Image: T_CarpetTile_Image,
  V_Banner_Vinyl_Image: V_Banner_Vinyl_Image,
  A_Accessories_Matting: Matting_accessories_banner,
  A_Accessories_Image_Matting: Matting_accessories,
  Category_Specials: Clearance_tile,
  Banner_Specials: Clearance_category_banner,
  DOCUMENT_BASE_URL: 'https://likewisefloors.co.uk/',
  API_BASE_URL: 'https://api.likewisefloors.co.uk/api/v1/mayne',
  API_EMAIL_BASE_URL: 'https://api.likewisefloors.co.uk/api/v1/email',
  IMAGE_BASE_URL: 'https://uploads.likewisefloors.co.uk/uploads/',
  IMAGE_BASE_OLD: (window.cordova) ? 'https://likewisefloors.co.uk' : '',
  fillColour: '#E26329',
  orangeCheckmarkSvg: orangeCheckmarkSvg,
  orangeCircle: orangCircleSvg,
  Warning: Warning,
  back: back,
  gtag: 'G-1KMB7REVPL',
  postFullBasket: false,
  suppressHeaderOnSearch: false,
  showCategoryInlineOnCheckout: false,
  showBreadcrumbOnCheckout: false,
  showContactHeader: false,
  showAvailableRollsInline: false,
  popupPasswordReset: true,
  showColourNames: false,
  contactHeaderImage: '',
  searchIcon: search,
  cartIcon: cart,
  cart: {
    deliveryDate: {
      title: 'Delivery Date',
      instructions: [
        "Please enter your chosen delivery date for your full order below. If you would like your order split over different delivery dates, please use the Delivery Date by Line drop down option on the left",
      ]
    }
  },
  pagination: {
    showFirst: true,
    showLast: true,
    prevIcon: false,
    nextIcon: false,
    even: false,
  },
  myAccount: {
    showLogin: false,
    headerImg: '',
  },
  newImageSolution: true, 
  enableQtyBreaks: true,
  orderHotline: '01204 701188',
  enablePresold: true,
  loginPage: {
    register: 'Contact Our Support Team',
    images: [loginImage]
  },
  source: 'lwweb',
  mattingBanner: [
    Matting_banner_1,
    Matting_banner_2,
    Matting_banner_3
  ],
  coretecCodes: [
    'JIA001', 'JIA002', 'USF001', 'USF002', 'USF003', 'USF004', 'USF005', 'USF006', 'USF007',
    'LGH001', 'LGH002'
  ],
  showHomeBanner: true,
  
  homeBanners: [
    {
      image: Pos_banner,
      smallImage: Pos_mobile,
      link: '/Likewise Displays Aug 2024 brochure.pdf',
    },
    {
      image: Clearance_banner,
      smallImage: Clearance_mobile,
      link: '/clearance',
    },
    {
     image: homeBanner1,
     smallImage: homeMob,
     link: '/search?searchSelection=vesta',
   }, 
  ],
  enableSpecials:true,
  headerBanner:[
    {
      text: 'Clearance and Specials <strong>Now Available</strong>',
      link: '/clearance'
    }
  ],

  enablePricelists: true, 
  priceListConfigs: priceListConfig,
  menuLabels: {
    about: 'About',
    account: 'My Account',
    accountInfo: 'Account Information',
    accountSummary: 'Account Summary',
    cats: 'Categories',
    changePassword: 'Change Password',
    contact: 'Contact',
    home: 'Home',
    logout: 'Logout',
    orders: 'My Orders',
    updateAccount: 'Update Account Info',
  },
  dashboard: {
    search: {
      labels: {
        superTitle: '',
      }
    },
    categories: {
      maxInList: -1,                             // -1 to show all
      labels: {
        viewAll: 'View All',
      },
    },
    activities: {
      view: 'quick',
      maxInList: 3,
      arrowColor: '#5d6076',
      labels: {
        title: 'Latest Activities',
        superTitle: '',           // Leave blank for none
        viewAll: 'View All',
      },
    },
  },
  categories: {
    header: {
      labels: {
        title: 'Categories',
      },
      image: '',
    },
    search: {
      labels: {
        superTitle: '',
        title: 'Choose from our wide range of products'
      }
    },
  },
  branch_name: '',
  branch_address1: '',
  branch_address2: '',
  branch_town: '',
  branch_pcode: '',
  branch_phone: '',
  branch_email: '',
  registration_email: 'webenquiries@likewisefloors.co.uk',
  enablePacks: true,
  showSubTitlesOnAccount: false,
  activePricelistCategories: [
    'C', 'N', 'G', 'V', 'N', 'L', 'H', 'E', 'T', 'B', 'A', 'Q', 'J'
  ]
};
